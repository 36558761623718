import { DateTime } from "luxon"

type Unit = "second" | "minute" | "hour" | "day" | "week" | "month"

type DiffUnit = {
  diff: number
  unit: Unit
}

export default function relativeDifferenceTimeWithUnit(date: Date): DiffUnit {
  const now = DateTime.now()
  const dateTime = DateTime.fromJSDate(date)
  const diff = dateTime.diff(now, [
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
  ])

  const timeDiffs = {
    second: Math.floor(diff.seconds),
    minute: Math.floor(diff.minutes),
    hour: Math.floor(diff.hours),
    day: Math.floor(diff.days),
    week: Math.floor(diff.weeks),
    month: Math.floor(diff.months),
  }

  const threshold = {
    second: 60,
    minute: 60,
    hour: 24,
    day: 7,
    week: 4,
  }

  const isInsideThreshold = (unit: Unit, threshold: number) =>
    timeDiffs[unit] > -threshold

  const units = ["second", "minute", "hour", "day", "week"] as const

  for (const unit of units) {
    if (isInsideThreshold(unit, threshold[unit])) {
      return { diff: timeDiffs[unit], unit }
    }
  }

  return { diff: timeDiffs.month, unit: "month" }
}

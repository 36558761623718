import { useIntl } from "react-intl"

import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

type SavingsPageLayoutProps = {
  children: React.ReactNode
}

export default function SavingsPageLayout({
  children,
}: SavingsPageLayoutProps) {
  const intl = useIntl()
  return (
    <div className="min-h-full bg-gray-100">
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              title={intl.formatMessage({
                id: "savingsPage.mobileNav.title",
                defaultMessage: "Savings",
              })}
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <>{children ?? <LoadingPagePlaceholder />}</>
      </GlowPageLayout>
    </div>
  )
}

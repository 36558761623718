/**
 * @generated SignedSource<<1751933e36edcc7cc5a89bd804fb181f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageMembershipDetailsPlanSection_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetailsPlanSectionFreeTrial_household" | "SettingsPageMembershipDetailsPlanSectionPlan_household">;
  readonly " $fragmentType": "SettingsPageMembershipDetailsPlanSection_household";
};
export type SettingsPageMembershipDetailsPlanSection_household$key = {
  readonly " $data"?: SettingsPageMembershipDetailsPlanSection_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetailsPlanSection_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageMembershipDetailsPlanSection_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetailsPlanSectionPlan_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetailsPlanSectionFreeTrial_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "2af314a39251f13579dcbd1d75fd0000";

export default node;

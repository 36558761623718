/**
 * @generated SignedSource<<6d53b1443b0ec4579920f6bb7729e763>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CashRewardsNavQuery$variables = Record<PropertyKey, never>;
export type CashRewardsNavQuery$data = {
  readonly household: {
    readonly availableRewards: {
      readonly amountInGbpPounds: number | null | undefined;
    };
  } | null | undefined;
};
export type CashRewardsNavQuery = {
  response: CashRewardsNavQuery$data;
  variables: CashRewardsNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Money",
  "kind": "LinkedField",
  "name": "availableRewards",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountInGbpPounds",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CashRewardsNavQuery",
    "selections": [
      {
        "alias": "household",
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "maybeHousehold",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CashRewardsNavQuery",
    "selections": [
      {
        "alias": "household",
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "maybeHousehold",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d015806c97091175fd011162aaf5eede",
    "id": null,
    "metadata": {},
    "name": "CashRewardsNavQuery",
    "operationKind": "query",
    "text": "query CashRewardsNavQuery {\n  household: maybeHousehold {\n    availableRewards {\n      amountInGbpPounds\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "de09b2ccd9cbd259f337d94f38622868";

export default node;

/**
 * @generated SignedSource<<4ca625262c95ae1ccda57e43ed5c7eb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsPremium_household$data = {
  readonly subscription: {
    readonly isOnFreeTrial: boolean | null | undefined;
    readonly isPremium: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "useIsPremium_household";
};
export type useIsPremium_household$key = {
  readonly " $data"?: useIsPremium_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsPremium_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsPremium_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdSubscriptionDetails",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPremium",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOnFreeTrial",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "beb0c65fe3fbc2394c98b776b5c69e00";

export default node;

/**
 * @generated SignedSource<<5cf1a723962f29a2103e163bafb88b81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageMembership_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetails_household" | "SettingsPageMembershipFeatures_household">;
  readonly " $fragmentType": "SettingsPageMembership_household";
};
export type SettingsPageMembership_household$key = {
  readonly " $data"?: SettingsPageMembership_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembership_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageMembership_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetails_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipFeatures_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "684d8b10a1a4c967a9604f5fe7f106cd";

export default node;

import { DateTime } from "luxon"
import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { EmailConnectionManager } from "src/components/EmailConnectionManager/EmailConnectionManager"
import {
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowIcon,
  GlowLink,
  GlowText,
} from "src/glow"
import { usePopup } from "src/hooks/modal"

import { OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection$key } from "./__generated__/OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection.graphql"
import { OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections$key } from "./__generated__/OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections.graphql"

type Props = {
  emailConnections: OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections$key | null
  onConnectEmail: () => void
}

export function OnboardingConnectEmailBenefitsStepConnectedEmailsSection(
  props: Props,
) {
  const intl = useIntl()
  const emailConnections = useFragment(
    graphql`
      fragment OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections on EmailConnection
      @relay(plural: true) {
        id
        ...OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection
      }
    `,
    props.emailConnections,
  )

  if (!emailConnections) {
    return null
  }

  return (
    <GlowFlexbox direction="column" gap="3" className="w-full">
      <GlowCard colour="transparent">
        {emailConnections.map((emailConnection) => (
          <ConnectedEmail
            key={emailConnection.id}
            emailConnection={emailConnection}
          />
        ))}
      </GlowCard>
      <GlowButton
        onClick={() => props.onConnectEmail()}
        variant="secondary"
        label={intl.formatMessage({
          id: "onboarding.connectEmailBenefits.connectedEmails.cta.connect",
          defaultMessage: "Connect another inbox",
        })}
        iconName="add_regular"
        className="w-full"
      />
    </GlowFlexbox>
  )
}

function ConnectedEmail(props: {
  emailConnection: OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection$key
}) {
  const intl = useIntl()

  const emailConnection = useFragment(
    graphql`
      fragment OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnection on EmailConnection {
        id
        email
        lastScannedAt
        source
      }
    `,
    props.emailConnection,
  )

  const emailConnectionEditor = usePopup()

  return (
    <>
      <GlowFlexbox gap="6" alignItems="start" justifyContent="center">
        {emailConnection?.source === "GOOGLE" && (
          <GlowIcon name="gmail" className="h-6 w-6" />
        )}
        {emailConnection?.source === "MICROSOFT" && (
          <GlowIcon name="microsoft" className="h-6 w-6" />
        )}
        <GlowFlexbox direction="column" alignItems="start">
          <GlowText fontWeight="medium">{emailConnection.email}</GlowText>
          {emailConnection.lastScannedAt && (
            <GlowText className="text-off-black-64">
              <FormattedMessage
                id="onboarding.connectEmailBenefits.emailConnected.lastScannedAt"
                defaultMessage="Last scanned at {date}"
                values={{
                  date: DateTime.fromISO(
                    emailConnection.lastScannedAt,
                  ).toFormat("dd MMM yyyy t"),
                }}
              />
            </GlowText>
          )}
        </GlowFlexbox>
        <GlowLink
          onClick={() => {
            emailConnectionEditor.open()
          }}
          label={intl.formatMessage({
            id: "onboarding.connectEmailBenefits.emailConnected.manage",
            defaultMessage: "Edit",
          })}
          className="ml-auto"
        />
      </GlowFlexbox>

      {emailConnection?.id && (
        <EmailConnectionManager
          emailConnectionId={emailConnection.id}
          isOpen={emailConnectionEditor.isOpen}
          onClose={() => {
            emailConnectionEditor.close()
          }}
        />
      )}
    </>
  )
}

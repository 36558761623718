/**
 * @generated SignedSource<<9d900d30c054f9c0950732db76666a5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageMembershipDetails_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetailsButtonSection_household" | "SettingsPageMembershipDetailsPlanSection_household" | "SettingsPageMembershipDetailsTitleSection_household">;
  readonly " $fragmentType": "SettingsPageMembershipDetails_household";
};
export type SettingsPageMembershipDetails_household$key = {
  readonly " $data"?: SettingsPageMembershipDetails_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetails_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageMembershipDetails_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetailsTitleSection_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetailsPlanSection_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetailsButtonSection_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "54f20cdcf2b97b415a041c254317d0a1";

export default node;

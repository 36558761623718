/**
 * @generated SignedSource<<cdcd65228ddae1bb0f6f7734c1edfb8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageMembershipDetailsPlanSectionFreeTrial_household$data = {
  readonly subscription: {
    readonly freeTrialCompletionPercentage: number | null | undefined;
    readonly freeTrialCountdownMonths: number | null | undefined;
    readonly freeTrialDaysLeft: number | null | undefined;
    readonly freeTrialEndDate: string | null | undefined;
    readonly isOnFreeTrial: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SettingsPageMembershipDetailsPlanSectionFreeTrial_household";
};
export type SettingsPageMembershipDetailsPlanSectionFreeTrial_household$key = {
  readonly " $data"?: SettingsPageMembershipDetailsPlanSectionFreeTrial_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetailsPlanSectionFreeTrial_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageMembershipDetailsPlanSectionFreeTrial_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdSubscriptionDetails",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOnFreeTrial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeTrialCompletionPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeTrialEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeTrialDaysLeft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeTrialCountdownMonths",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "03fa20d989ed722eaea5809f22929fc0";

export default node;

import React from "react"

import { useNavigate } from "@tanstack/react-router"
import { IntlShape, useIntl } from "react-intl"
import { graphql, useLazyLoadQuery } from "react-relay"

import { useCheckGate } from "src/Gate"
import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import { useViewport, Viewport } from "src/hooks/responsive"
import {
  GBP_TWO_DECIMAL_PLACES_CONFIG,
  GBP_ZERO_DECIMAL_PLACES_CONFIG,
} from "src/intl"

import { CashRewardsNavQuery as CashRewardsNavQueryType } from "./__generated__/CashRewardsNavQuery.graphql"

import { LoadingDots } from "../LoadingDots/LoadingDots"

export const CashRewardsNavQuery = graphql`
  query CashRewardsNavQuery {
    household: maybeHousehold {
      availableRewards {
        amountInGbpPounds
      }
    }
  }
`

function formatCashRewards(intl: IntlShape, amount: number) {
  return {
    sm: intl.formatNumber(Math.floor(amount), GBP_ZERO_DECIMAL_PLACES_CONFIG),
    md: intl.formatNumber(amount, GBP_TWO_DECIMAL_PLACES_CONFIG),
  }
}

function CashRewardsItem() {
  const intl = useIntl()
  const navigate = useNavigate()
  const checkGate = useCheckGate()
  const isMobile = useViewport() <= Viewport.SM

  const data = useLazyLoadQuery<CashRewardsNavQueryType>(
    CashRewardsNavQuery,
    {},
  )

  const cashRewardsAmount = data.household?.availableRewards?.amountInGbpPounds

  if (
    cashRewardsAmount === null ||
    cashRewardsAmount === undefined ||
    !checkGate("available_cash_rewards")
  ) {
    return null
  }

  const formattedAmount = formatCashRewards(intl, cashRewardsAmount)

  return (
    <GlowFlexbox
      as="button"
      gap={{ sm: "1", md: "2" }}
      alignItems="center"
      className="border-off-black-12 hover:border-off-black-20 h-10 rounded-full bg-white px-3 py-2 md:border"
      onClick={() => {
        navigate({
          to: "/savings",
        })
      }}
    >
      <GlowIcon name="money_wallet_bold" className="h-4 w-4" />
      <GlowText fontWeight="medium" size={{ sm: "sm", md: "base" }}>
        {isMobile ? formattedAmount.sm : formattedAmount.md}
      </GlowText>
    </GlowFlexbox>
  )
}

export default function CashRewardsNav() {
  return (
    <>
      {
        <React.Suspense fallback={<LoadingDots />}>
          <CashRewardsItem />
        </React.Suspense>
      }
    </>
  )
}

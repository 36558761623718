import clsx from "clsx"

type GlowProgressBarVariant = "nous-glow" | "virtuous-green"

type Props = {
  /**
   * A number between 0 and 1
   */
  decimalPercentageComplete?: number
  variant: GlowProgressBarVariant
} & React.ComponentProps<"div">

export function GlowProgressBar({
  className,
  variant,
  decimalPercentageComplete = 0,
  ...props
}: Props) {
  return (
    <div
      className={clsx([
        "flex h-2 items-start overflow-hidden rounded bg-gray-500/10",
        className,
      ])}
      {...props}
    >
      <div
        className={clsx(
          "h-2 rounded transition-[width] duration-500",
          variant === "nous-glow"
            ? "bg-nous-glow-400"
            : "bg-virtuous-green-400",
        )}
        style={{
          width: `${
            Math.min(Math.max(decimalPercentageComplete, 0), 1) * 100
          }%`,
        }}
      ></div>
    </div>
  )
}

import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { useCheckGate } from "src/Gate"
import { GlowCard, GlowFlexbox, GlowIcon, GlowLink, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { usePopup } from "src/hooks/modal"
import { useIsPremium } from "src/hooks/useIsPremium"

import { SettingsPageMembership_household$key } from "./__generated__/SettingsPageMembership_household.graphql"
import { SettingsPageMembershipFeatures_household$key } from "./__generated__/SettingsPageMembershipFeatures_household.graphql"

import { SettingsPageComingSoon } from "../SettingsPageComingSoon"
import { SettingsPageMembershipDetails } from "../SettingsPageMembershipDetails"

export const SettingsPageMembership = (props: {
  household: SettingsPageMembership_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembership_household on Household {
        ...SettingsPageMembershipDetails_household
        ...SettingsPageMembershipFeatures_household
      }
    `,
    props.household,
  )

  const checkGate = useCheckGate()
  const membershipSettingsEnabled = checkGate("membership_settings")

  const cancelFreeTrialPopup = usePopup()

  const intl = useIntl()

  const supportEmail = intl.formatMessage({
    id: "page.settings.view.membership.supportEmail",
    defaultMessage: "support@nous.co",
  })

  if (!membershipSettingsEnabled) {
    return (
      <GlowCard>
        <SettingsPageComingSoon />
      </GlowCard>
    )
  }

  return (
    <>
      <GlowFlexbox direction="column" gap="4">
        <SettingsPageMembershipDetails
          household={household}
          onCancelFreeTrial={cancelFreeTrialPopup.open}
        />
        <SettingsPageMembershipFeatures household={household} />
      </GlowFlexbox>

      <GlowModal
        isOpen={cancelFreeTrialPopup.isOpen}
        onClose={cancelFreeTrialPopup.close}
        title={intl.formatMessage({
          id: "page.settings.view.membership.cancelFreeTrial.modal.title",
          defaultMessage: "Cancel Free Trial",
        })}
      >
        <GlowFlexbox direction="column" gap="4">
          <GlowText size="base">
            <FormattedMessage
              id="page.settings.view.membership.cancelFreeTrial.modal.paragraph.1"
              defaultMessage="Please contact us via WhatsApp or at {supportEmail} if you have concerns about free trial."
              values={{
                supportEmail: (
                  <GlowLink
                    href={`mailto:${supportEmail}`}
                    label={supportEmail}
                    size="base"
                    className="!inline-block"
                  />
                ),
              }}
            />
          </GlowText>
          <GlowText size="base">
            <FormattedMessage
              id="page.settings.view.membership.cancelFreeTrial.modal.paragraph.2"
              defaultMessage="Remember: You won't be charged at the end of the free trial. We'll automatically move you to Nous Free if you decide not to stay on Premium."
            />
          </GlowText>
        </GlowFlexbox>
      </GlowModal>
    </>
  )
}

const SettingsPageMembershipFeatures = (props: {
  household: SettingsPageMembershipFeatures_household$key
}) => {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipFeatures_household on Household {
        ...useIsPremium_household
      }
    `,
    props.household,
  )

  const isPremium = useIsPremium(household)

  if (!isPremium) {
    return null
  }

  return (
    <GlowCard>
      <GlowFlexbox direction="column" gap="6" alignItems="start">
        <GlowText size="base" fontWeight="bold">
          <FormattedMessage
            id="page.settings.view.membership.features.title"
            defaultMessage="You've got access to these Premium features:"
          />
        </GlowText>
        <GlowFlexbox direction="column" gap="2">
          {features.map((feature, index) => {
            return (
              <GlowFlexbox
                key={`feature-${index}`}
                direction="row"
                gap="2"
                alignItems="center"
                justifyContent="start"
              >
                <GlowIcon name="check_circle_1_bold" className="h-5 w-5" />
                <GlowText size="base">
                  <FormattedMessage {...feature} />
                </GlowText>
              </GlowFlexbox>
            )
          })}
        </GlowFlexbox>
        <GlowLink
          size="sm"
          href="http://nous.co/pricing"
          target="_blank"
          rel="noreferrer"
          label={intl.formatMessage({
            id: "page.settings.view.membership.features.learnMore",
            defaultMessage: "Learn more about Nous Premium",
          })}
        />
      </GlowFlexbox>
    </GlowCard>
  )
}

export const features = [
  defineMessage({
    id: "page.settings.view.membership.features.1",
    defaultMessage: "Cash rewards",
  }),
  defineMessage({
    id: "page.settings.view.membership.features.2",
    defaultMessage: "Priority live chat support",
  }),
  defineMessage({
    id: "page.settings.view.membership.features.3",
    defaultMessage: "Management for all your household services",
  }),
  defineMessage({
    id: "page.settings.view.membership.features.4",
    defaultMessage: "Choice of savings mode",
  }),
]

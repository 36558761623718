import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { useIsPremium_household$key } from "./__generated__/useIsPremium_household.graphql"

export const useIsPremium = (householdKey: useIsPremium_household$key) => {
  const household = useFragment(
    graphql`
      fragment useIsPremium_household on Household {
        subscription {
          isPremium
          isOnFreeTrial
        }
      }
    `,
    householdKey,
  )

  return (
    !household.subscription ||
    household.subscription.isPremium ||
    household.subscription.isOnFreeTrial
  )
}

export default useIsPremium

import { getRouteApi, useNavigate } from "@tanstack/react-router"
import { useIntl } from "react-intl"
import { graphql, usePreloadedQuery } from "react-relay"

import MetaTags from "src/components/MetaTags/MetaTags"
import { GlowFlexbox, GlowLayoutCentered } from "src/glow"
import useRedirectToWaitlist from "src/hooks/useRedirectToWaitlist"
import usePageTrack from "src/tracking/usePageTrack"
import useTracking from "src/tracking/useTracking"

import { SettingsPageQuery } from "./__generated__/SettingsPageQuery.graphql"
import { SettingsPageAccount } from "./components/SettingsPageAccount/SettingsPageAccount"
import { SettingsPageMembership } from "./components/SettingsPageMembership/SettingsPageMembership"
import { SettingsPageNavButton } from "./components/SettingsPageNavButton"
import { SettingsPageOverview } from "./components/SettingsPageOverview/SettingsPageOverview"
import { SettingsPageSavingsMode } from "./components/SettingsPageSavingsMode/SettingsPageSavingsMode"
import { SettingsPageLayout } from "./SettingsPageLayout"
import { SettingsPageSubPageBankAccounts } from "./subPages/SettingsPageSubPageBankAccounts"
import { SettingsPageSubPageInboxes } from "./subPages/SettingsPageSubPageInboxes"
import { SettingsPageSubPageMembers } from "./subPages/SettingsPageSubPageMembers"
import { SettingsPageSubPageNotifications } from "./subPages/SettingsPageSubPageNotifications"
import { titleMap } from "./utils"

const routeApi = getRouteApi("/settings/$")

const POSSIBLE_VIEWS = [
  "overview",
  "membership",
  "savings-mode",
  "account",
] as const
type POSSIBLE_VIEW = (typeof POSSIBLE_VIEWS)[number]

const POSSIBLE_SUB_VIEWS = [
  "members",
  "bank-accounts",
  "inboxes",
  "notifications",
] as const

export const VIEWS = [...POSSIBLE_VIEWS, ...POSSIBLE_SUB_VIEWS]
export type VIEW = (typeof VIEWS)[number]

export const SettingsPage = () => {
  usePageTrack("Settings")

  const query = routeApi.useLoaderData()
  const { _splat: section }: { _splat: VIEW } = routeApi.useParams()
  const intl = useIntl()

  const data = usePreloadedQuery<SettingsPageQuery>(
    graphql`
      query SettingsPageQuery {
        household {
          ...SettingsPageOverview_household
          ...SettingsPageSavingsMode_household
          ...SettingsPageSubPageMembers_household
          ...SettingsPageSubPageBankAccounts_household
          ...SettingsPageSubPageInboxes_household
          ...useRedirectToWaitlist_household
          ...SettingsPageMembership_household
        }
        currentUser {
          ...SettingsPageOverview_user
        }
      }
    `,
    query,
  )

  useRedirectToWaitlist({ household: data.household ?? null })

  const navigate = useNavigate()
  const track = useTracking()

  const setView = (section: VIEW) => {
    track(["Settings", "Opened", { section }])

    if (section === "overview") {
      return navigate({
        to: "/settings",
        resetScroll: true,
      })
    }

    return navigate({
      to: "/settings/$",
      params: {
        _splat: section,
      },
      resetScroll: true,
    })
  }

  return (
    <>
      <MetaTags
        title={intl.formatMessage({
          id: "page.settings.title",
          defaultMessage: "Settings",
        })}
        description={intl.formatMessage({
          id: "page.settings.description",
          defaultMessage: "Settings page",
        })}
      />

      {POSSIBLE_VIEWS.includes(section as POSSIBLE_VIEW) && (
        <SettingsPageLayout title={intl.formatMessage(titleMap[section])}>
          <div className="relative mx-auto w-full max-w-7xl">
            <GlowFlexbox
              direction="column"
              margin={{
                sm: { top: "4" },
                md: { top: "10" },
              }}
              padding={{ x: "4" }}
            >
              <GlowLayoutCentered>
                {section === "overview" && (
                  <SettingsPageOverview
                    household={data.household}
                    user={data.currentUser}
                    onGoToAccountView={() => setView("account")}
                    onGoToMembershipView={() => setView("membership")}
                    onGoToSavingsModeView={() => setView("savings-mode")}
                    onGoToMembersView={() => setView("members")}
                    onGoToBankAccountsView={() => setView("bank-accounts")}
                    onGoToInboxesView={() => setView("inboxes")}
                    onGoToNotificationsView={() => setView("notifications")}
                  />
                )}

                {section === "membership" && (
                  <SettingsPageMembership household={data.household} />
                )}

                {section === "savings-mode" && (
                  <SettingsPageSavingsMode household={data.household} />
                )}

                {section === "account" && <SettingsPageAccount />}
              </GlowLayoutCentered>
            </GlowFlexbox>

            <GlowFlexbox
              direction="column"
              gap="4"
              alignItems="start"
              className="absolute left-0 top-0 mx-4 hidden md:flex"
            >
              <SettingsPageNavButton
                label={intl.formatMessage({
                  id: "page.settings.view.overview.title",
                  defaultMessage: "Overview",
                })}
                active={section === "overview"}
                onClick={() => setView("overview")}
              />
              <SettingsPageNavButton
                label={intl.formatMessage({
                  id: "page.settings.view.membership.title",
                  defaultMessage: "Membership",
                })}
                active={section === "membership"}
                onClick={() => setView("membership")}
              />
              <SettingsPageNavButton
                label={intl.formatMessage({
                  id: "page.settings.view.savings.title",
                  defaultMessage: "Savings mode",
                })}
                active={section === "savings-mode"}
                onClick={() => setView("savings-mode")}
              />
              <SettingsPageNavButton
                label={intl.formatMessage({
                  id: "page.settings.view.account.title",
                  defaultMessage: "Account",
                })}
                active={section === "account"}
                onClick={() => setView("account")}
              />
            </GlowFlexbox>
          </div>
        </SettingsPageLayout>
      )}

      {section === "members" && (
        <SettingsPageSubPageMembers
          household={data.household}
          onBackToOverview={() => setView("overview")}
        />
      )}

      {section === "bank-accounts" && (
        <SettingsPageSubPageBankAccounts
          household={data.household}
          onBackToOverview={() => setView("overview")}
        />
      )}

      {section === "inboxes" && (
        <SettingsPageSubPageInboxes
          household={data.household}
          onBackToOverview={() => setView("overview")}
        />
      )}

      {section === "notifications" && (
        <SettingsPageSubPageNotifications
          onBackToOverview={() => setView("overview")}
        />
      )}
    </>
  )
}

export default SettingsPage

import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import {
  GlowFlexbox,
  GlowIcon,
  GlowLink,
  GlowText,
  GlowTooltip,
  Position,
} from "src/glow"
import { Viewport, useViewport } from "src/hooks/responsive"

type PremiumBadgeProps = {
  className?: string
  overrideTooltipPosition?: Position
  size?: "sm" | "md"
  withTooltip?: boolean
  showLabel?: boolean
  customLabel?: string
}

const mapHeightToClassName = {
  sm: "h-6",
  md: "h-8",
}

const mapWidthToClassName = {
  sm: "w-6",
  md: "w-8",
}

export function PremiumBadge({
  className,
  overrideTooltipPosition,
  size = "sm",
  withTooltip = true,
  showLabel = true,
  customLabel,
}: PremiumBadgeProps) {
  const intl = useIntl()
  const isMobile = useViewport() < Viewport.MD

  const premiumBadge = (
    <div
      className={clsx(
        "bg-nous-glow-200 relative grid place-items-center rounded-md",
        mapHeightToClassName[size],
        showLabel ? "w-[112px]" : mapWidthToClassName[size],
      )}
    >
      <div className="shimmer-gradient animate-shimmer absolute inset-0 left-0 top-0 h-full w-full bg-[size:800%] bg-right"></div>
      <GlowFlexbox
        gap="1"
        justifyContent="center"
        alignItems="center"
        className="h-full w-full"
      >
        <LogoIcon size={size} variant="premium" />

        {showLabel && (
          <>
            <GlowText isDisplay fontWeight="medium" size="sm">
              {customLabel ?? (
                <FormattedMessage
                  id="premiumBadge.text"
                  defaultMessage="Premium"
                />
              )}
            </GlowText>

            {withTooltip && (
              <GlowIcon
                name="information_circle_bold"
                className="text-off-black-32 h-3 w-3 shrink-0"
              />
            )}
          </>
        )}
      </GlowFlexbox>
    </div>
  )

  if (!withTooltip) {
    return <div className={className}>{premiumBadge}</div>
  }

  return (
    <div className={className}>
      <GlowTooltip
        handle={premiumBadge}
        overridePosition={
          overrideTooltipPosition
            ? overrideTooltipPosition
            : isMobile
              ? "left"
              : "top"
        }
      >
        <FormattedMessage
          id="premiumBadge.tooltip"
          defaultMessage="You are receiving this proposal as a Premium Member. This means you get access to Cash Rewards.<a>Learn more</a>"
          values={{
            a: (chunk) => (
              <GlowLink
                size="xs"
                className="!inline"
                label={chunk}
                href={intl.formatMessage({
                  id: "premiumBadge.tooltip.url",
                  defaultMessage: "https://www.nous.co/pricing",
                })}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </GlowTooltip>
    </div>
  )
}

const logoSizeClassMap = {
  sm: "w-3 h-3",
  md: "w-[18px] h-[18px]",
  lg: "w-8 h-8",
  xl: "w-20 h-20",
}

const logoBackgroundSizeClassMap = {
  sm: "w-6 h-6",
  md: "w-8 h-8",
  lg: "w-16 h-16",
  xl: "w-24 h-24",
}

const logoBackgroundClassMap = {
  premium: "bg-nous-glow-200",
  free: "bg-gray-100",
}

export function LogoIcon({
  size = "sm",
  variant = "premium",
  withBackground = false,
}: {
  size?: keyof typeof logoSizeClassMap
  variant?: "premium" | "free"
  withBackground?: boolean
}) {
  const logo = (
    <div
      className={clsx(
        "relative shrink-0 rounded-t-[50%]",
        variant === "premium" ? "radial-gradient" : "bg-white",
        logoSizeClassMap[size],
      )}
    ></div>
  )

  if (withBackground) {
    return (
      <div
        className={clsx(
          "relative grid place-items-center rounded-md",
          logoBackgroundClassMap[variant],
          logoBackgroundSizeClassMap[size],
        )}
      >
        {logo}
      </div>
    )
  }

  return logo
}

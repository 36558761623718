import { useNavigate } from "@tanstack/react-router"
import { FormattedMessage } from "react-intl"

import { GlowFlexbox, GlowText } from "src/glow"

export type Breadcrumb = {
  route?: string
  params?: Record<string, string>
  text: string
  onClick?: () => void
}

type BreadcrumbsProps = {
  crumbs: [Breadcrumb, ...Breadcrumb[]]
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  const lastIndex = crumbs.length - 1
  const navigate = useNavigate()
  return (
    <GlowFlexbox gap="2">
      {crumbs.map(({ text, route, params, onClick }, index) => {
        return (
          <GlowFlexbox gap="2" key={text}>
            {route || onClick ? (
              <>
                <button
                  onClick={() =>
                    onClick?.() ??
                    navigate({
                      to: route,
                      params,
                    })
                  }
                >
                  <GlowText
                    size="xl"
                    isDisplay
                    className="text-off-black-32 hover:text-gray-500"
                  >
                    {text}
                  </GlowText>
                </button>
                {index < lastIndex && (
                  <GlowText size="xl" isDisplay className="text-off-black-32">
                    <FormattedMessage
                      id="breadcrumb.separator"
                      defaultMessage="/"
                    />
                  </GlowText>
                )}
              </>
            ) : (
              <>
                <GlowText size="xl" isDisplay>
                  {text}
                </GlowText>
                {index < lastIndex && (
                  <GlowText size="xl" isDisplay className="text-off-black-32">
                    <FormattedMessage
                      id="breadcrumb.separator"
                      defaultMessage="/"
                    />
                  </GlowText>
                )}
              </>
            )}
          </GlowFlexbox>
        )
      })}
    </GlowFlexbox>
  )
}

import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { SettingsPageOverviewMembership_household$key } from "./__generated__/SettingsPageOverviewMembership_household.graphql"

import { SettingsPageMembershipDetails } from "../../SettingsPageMembershipDetails"

export const SettingsPageOverviewMembership = (props: {
  household: SettingsPageOverviewMembership_household$key
  onGoToMembershipView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewMembership_household on Household {
        ...SettingsPageMembershipDetails_household
      }
    `,
    props.household,
  )

  return (
    <SettingsPageMembershipDetails
      household={household}
      onGoToMembershipView={props.onGoToMembershipView}
    />
  )
}

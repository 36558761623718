/**
 * @generated SignedSource<<5705ab03ca8531094b7d110c618f8ec4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverviewMembership_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageMembershipDetails_household">;
  readonly " $fragmentType": "SettingsPageOverviewMembership_household";
};
export type SettingsPageOverviewMembership_household$key = {
  readonly " $data"?: SettingsPageOverviewMembership_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverviewMembership_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverviewMembership_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageMembershipDetails_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "be4b44d0c65cf0b6866f61132fe1d731";

export default node;
